<template>
  <footer class="min-w-[1200px]">
    <div class="footer-top bg-[#3b3b3b] py-6">
      <div class="container min-w-[1200px] md:w-[1400px] flex flex-row items-center md:justify-between mx-auto py-4">
        <div class="flex items-start columns-8">
          <ul class="w-ul h-[160px] text-[#c2c2c2] px-4 border-[#6e6d6d] border-r"
            v-for="(item, index) in navList" :key="index">
            <li class="text-[14px] mb-3 w-nav-first cursor-pointer" @click="navigateNavTo(item, index)">{{ item.name }}</li>
            <li class="text-[13px] w-nav flex line-clamp-1 flex-nowrap mr-4" v-for="(child, i) in item.child" :key="i"
              @click="navigateTo(item, index, i, child)">{{ child.name }}</li>
          </ul>
        </div>
        <div class="flex items-start md:mt-10">
          <div class="mx-4">
            <ul class="text-[#c2c2c2] text-[13px]">
              <li class="w-info"><span class="w-[60px] inline-block">{{ $t('邮箱：') }}</span>{{ footInfo.web_site_email }}
              </li>
              <li class="w-info"><span class="w-[60px] inline-block">{{ $t('网址：') }}</span>{{
                footInfo.store_web_site_domain }}</li>
              <li class="w-info flex items-start">
                <span class="w-[60px] inline-block">{{ $t('地址：') }}</span>
                <div class="flex-1 flex-col w-address">
                  <p class="" v-for="(item,index) in footInfo.web_site_address" :key="index">{{ item }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="flex flex-col items-center justify-center">
            <img class="w-[100px]" :src="footInfo.qrcodeur" alt="">
            <p class="text-[#c2c2c2] text-[13px] mt-2">{{ $t('公众号二维码') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom bg-[#242424] text-[14px]">
      <div class="container w-[1400px] flex items-center justify-between mx-auto py-4">
        <ul class="flex items-center text-[#c2c2c2]">
          <li>{{ footInfo.web_site_title }}</li>
        </ul>
        <div class="copyright text-[#c2c2c2]"> {{ footInfo.web_site_copyright }}</div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { h, ref, reactive, computed, shallowRef, onMounted } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import { useStore } from 'vuex'
const store = useStore();
//取到state面的local
const local = computed(() => store.state.local);

const props = defineProps({
  navList: {
    type: Array,
    default() {
      return []
    }
  },
  footInfo: {
    type: Object,
    default() { }
  }
});

const curIndex = computed({
  get: () => store.state.curIndex,
  set: (value) => store.commit('setCurIndex', value)
});

const onLanguage = (item) => {
  emits('on-language');
}

const subNavId = computed({
  get: () => store.state.childId,
  set: (value) => {
    store.commit('setSubNavId', value)
  }
})

//导航
const navigateNavTo = (item, index) => {
  curIndex.value = index;
  store.commit('setCurIndex', index);
  switch (index) {
    case 3:
      item.childId = 32
      break;
    case 4:
      item.childId = 45
      break;
    case 5:
      item.childId = 50
      break;
    case 6:
      item.childId = 54
      break;
  }
  store.commit('setSubNavId', item.childId);
  router.push({
    name: item.path,
    params: {
      pid: item.aid,
      name: item.name,
      aid: item.childId ? item.childId : item.aid
    }
  })
}

// 子导航
const navigateTo = (item, index, i, child) => {
  // console.log(item)
  // console.log(child)
  curIndex.value = index;
  store.commit('setCurIndex', index);
  subNavId.value = child.aid
  store.commit('setSubNavId', child.aid);
  router.push({
    name: child.path,
    params: {
      pid: item.aid,
      name: item.name,
      aid: child.aid
    } 
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.w-ul:nth-child(1)>li:nth-child(1) {
  &:hover {
    color: #fff !important;
    cursor: pointer;
  }
}

.w-ul:nth-child(8) li:nth-child(1) {
  &:hover {
    color: #fff !important;
    cursor: pointer;
  }
}

// .w-ul:nth-child(4) {
//   width: 210px
// }

.w-ul:last-child {
  border: none;
}

.w-ul:first-child {
  width: 90px
}

.footer-top {
  // background-image: url('@/assets/images/h_9.png');
  background-size: 100% 300px;

  .w-info {
    width: 260px;
    margin-bottom: 10px;
  }

  .w-nav-first {
    height: 20px;
    word-wrap: break-word;
    /* 或者使用 overflow-wrap */
    overflow: hidden;
    overflow-wrap: break-word;
    margin-bottom: 15px;
  }

  .w-nav {
    height: 20px;
    word-wrap: break-word;
    /* 或者使用 overflow-wrap */
    overflow-wrap: break-word;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }
}

.w-address {
  display: inline-block;
  line-height: 24px;
  padding-top: 0;
}
</style>
