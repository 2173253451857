import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'swiper/css';
import { createI18n } from 'vue-i18n'
import messages from "./locales/index";
import './assets/tailwind.css'
import storage from "./utils/storage"

const i18n = createI18n({
  legacy: false,          
  globalInjection: true, 
  locale: storage.get('Language') || '' ,
  messages
})

const app = createApp(App)
app.use(router)
app.use(store)
app.use(i18n)
app.use(ElementPlus)
app.mount('#app')

// createApp(App).use(store).use(router).mount('#app')
