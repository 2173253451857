/**
 * @author zrr
 * @description 表单公共验证方法封装
 */

export const validate = (value, vtype) => {
	if (vtype === 'require') {
	  return !!value
	}
	if (vtype === 'phone') {
	  return /^1[3-9]\d{9}$/.test(value)
	}
	// 验证码
	if (vtype === 'captcha') {
	  return /^[0-9]{6}$/.test(value)
	}
	// 数字
	if (vtype === 'isnumber') {
	  return /^[0-9]{5,11}$/.test(value)
	}
	//QQ
	if (vtype === 'isqq') {
	  return /^[1-9][0-9]{4,10}$/.test(value)
	}
	// 身份证号码验证不准确
	if (vtype === 'idcard') {
	  return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)
	}
	//
	if (vtype === 'password') {
	  return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z]{6,12}$/.test(value)
	}
	// 邀请码验证
	if (vtype === 'invitecode') {
	  return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z]$/.test(value)
	}
	// 判断不准确 比如123.
	if (vtype === 'ismoney') {
	  return /^(([1-9]\d*)|\d)(\.\d{1,2})?$/.test(value)
	}
	if (vtype === 'email') {
	  // return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value);
	  return /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(value)
	}
	if (vtype === 'url') {
	  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
	}
	if (vtype === 'paragraph') {
	  return /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/.test(value)
	}
  }
  
export function checkCommon (reg, tiptMsg, requiredMsg) {
	return (rule, value, callback) => {
		if (rule.required && !value) {
      return callback(new Error(requiredMsg || '必填项不能为空'))
		} else if (!rule.required && !value) {
			callback()
		} else {
			if (reg.test(value)) {
				callback()
			} else {
				return callback(new Error(tiptMsg))
			}
		}
	}
}

export const maxNum = 1000000000000000

// 车牌号校验
export function checkCarNo (tiptMsg, requiredMsg) {
	const reg = /(^[\u4E00-\u9FA5]{1}[A-Z0-9]{6}$)|(^[A-Z]{2}[A-Z0-9]{2}[A-Z0-9\u4E00-\u9FA5]{1}[A-Z0-9]{4}$)|(^[\u4E00-\u9FA5]{1}[A-Z0-9]{5}[挂学警军港澳]{1}$)|(^[A-Z]{2}[0-9]{5}$)|(^(08|38){1}[A-Z0-9]{4}[A-Z0-9挂学警军港澳]{1}$)/
	return checkCommon(reg, tiptMsg || '请输入正确的车牌号', requiredMsg)
}

// 手机号验证
export function checkPhone (tiptMsg, requiredMsg) {
	const reg = /^1[3456789]\d{9}$/
	return checkCommon(reg, tiptMsg || '请输入正确的手机号', requiredMsg)
}

// 身份证号校验
export function checkIDCard (tiptMsg, requiredMsg) {
	const reg =
		/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
	return checkCommon(reg, tiptMsg || '请输入正确的身份证号', requiredMsg)
}

// 邮箱验证
export function checkEmail (tiptMsg, requiredMsg) {
	const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
	// const reg = /^([a-zA-Z0-9_-]|[~!@#$%^&*()[\]\\_+`\-={}:";'<>?,./])+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
	return checkCommon(reg, tiptMsg || '请输入正确的邮箱', requiredMsg)
}

// 地址链接验证
export function checkLink (tiptMsg, requiredMsg) {
	const reg = new RegExp(
		'^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
		'i'
	)
	return checkCommon(reg, tiptMsg || '请输入正确的链接地址', requiredMsg)
}

// 校验联系电话
export function checkContPhone (tiptMsg, requiredMsg) {
	// 手机号码
	const reg1 = /^1[3456789]\d{9}$/
	// 座机格式
	const reg2 = /^0?1[3|4|5|8][0-9]\d{8}$/
	return (rule, value, callback) => {
		if (rule.required && !value) {
			return callback(new Error(requiredMsg || '必填项不能为空'))
		} else if (!rule.required && !value) {
			callback()
		} else {
			if (reg1.test(value) || reg2.test(value)) {
				callback()
			} else {
				return callback(new Error(tiptMsg || '请输入正确的联系方式'))
			}
		}
	}
}

// 校验联系电话
export function checkTel (tiptMsg, requiredMsg) {
	// 座机格式
	const reg = /^0?1[3|4|5|8][0-9]\d{8}$/
	return (rule, value, callback) => {
		if (rule.required && !value) {
			return callback(new Error(requiredMsg || '必填项不能为空'))
		} else if (!rule.required && !value) {
			callback()
		} else {
			if (reg) {
				callback()
			} else {
				return callback(new Error(tiptMsg || '请输入正确的电话'))
			}
		}
	}
}

// 校验姓名
export function checkName (tiptMsg, requiredMsg) {
	const reg = /^[\u4e00-\u9fa5]{2,4}$/
	return checkCommon(reg, tiptMsg || '请输入正确的姓名', requiredMsg)
}

// 校验年龄
export function checkAge (tiptMsg, requiredMsg) {
	const reg = /^(([0-9]|[1-9][1-9]|1[0-7][0-9])(\\.[0-9]+)?|180)$/
	return checkCommon(reg, tiptMsg || '请输入正确的年龄', requiredMsg)
}

// 校验金额
export function checkMoney (tiptMsg, requiredMsg) {
	const reg =
		/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
	return checkCommon(reg, tiptMsg || '请输入正确的金额', requiredMsg)
}

// 校验正整数
export function checkPositiveIntNum (tiptMsg, requiredMsg) {
	const reg = /(^[1-9]\d*$)/
	return (rule, value, callback) => {
		if (rule.required && !value) {
			return callback(new Error(requiredMsg || '必填项不能为空'))
		} else if (!rule.required && !value) {
			callback()
		} else {
			if (value == 0) {
				return callback(new Error(tiptMsg))
			} else {
				if (reg.test(value)) {
					callback()
				} else {
					return callback(new Error(tiptMsg))
				}
			}
		}
	}
}

// 校验正数
export function checkPositiveNum (tiptMsg, requiredMsg) {
	const reg = /(^[1-9]\d*$)/
	return checkCommon(reg, tiptMsg || '格式为正数', requiredMsg)
}

// 校验非负整数
export function checkNoNegativeNum (tiptMsg, requiredMsg) {
	const reg = /^\d+$/
	return checkCommon(reg, tiptMsg || '格式为非负整数', requiredMsg)
}

// 校验非负数
export function checkFfNum (tiptMsg, requiredMsg) {
	const reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
	return checkCommon(reg, tiptMsg || '格式为非负数', requiredMsg)
}

// 校验传真
export function checkFax (tiptMsg, requiredMsg) {
	const reg = /^(\d{3,4}-)?\d{7,8}$/
	return checkCommon(reg, tiptMsg || '传真格式不正确', requiredMsg)
}

// 校验统一社会信用代码正则表达式
export function checkPositiveLettertAndRequired (tiptMsg, requiredMsg) {
	const reg =
		/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{17})$/
	return checkCommon(
		reg,
		tiptMsg || '请统一社会信用代码，格式由18位数字或大写拉丁字母组成',
		requiredMsg
	)
}

// 判断数组不能为空
export function notEmptyNumber (requiredMessage) {
	return (rule, value, callback) => {
		let newvalue
		if (value instanceof Array) {
			newvalue = value
		} else {
			newvalue = []
		}
		if (rule.required && newvalue.length === 0) {
			return callback(new Error(requiredMessage || '必填项不能为空'))
		} else {
			callback()
		}
	}
}

export default {
	checkCommon,
	maxNum,
	checkCarNo,
	checkPhone,
	checkIDCard,
	checkEmail,
	checkLink,
	checkContPhone,
	checkTel,
	checkName,
	checkAge,
	checkMoney,
	checkPositiveIntNum,
	checkPositiveNum,
	checkNoNegativeNum,
	checkFfNum,
	checkFax,
	checkPositiveLettertAndRequired,
	notEmptyNumber
}
