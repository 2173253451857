import axios from 'axios'
import { ElMessage, ElLoading  } from 'element-plus'
import { BASE_URL } from "./config"
import storage from "../utils/storage"
let loadingInstance = null // 加载全局的loading

const request = axios.create({ 
    baseURL: BASE_URL,
    // withCredentials: true,  // 表示跨域请求时是否需要使用凭证
    timeout: 60000, // 设置超时时间60s
})

console.log()


/** 添加请求拦截器 **/
request.interceptors.request.use(config => {
    config.headers['content-type'] = "application/json;charset=UTF-8";
    config.headers['lang'] = storage.get('Language') && storage.get('Language') == 'en' ? storage.get('Language') : ''
    if(storage.get('token')){
        config.headers['user-token'] = storage.get('token')
    } 
    
    loadingInstance = ElLoading.service({   // 发起请求时加载全局loading，请求失败或有响应时会关闭
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })
    return config
}, error => {
    // console.log('请求错误，原因：', error)
    return Promise.reject(error)
})


/** 添加响应拦截器  **/
request.interceptors.response.use(response => {
    loadingInstance.close()
    if(response.data.code == 1){
        return Promise.resolve(response.data)
    } else if(response.data.code == 1000){
        return Promise.reject(response.data)
    } else{
        const status = response.data.code;
        switch (status) {
            case '-201' ||'-202' ||  '-203':
                ElMessage({
                    message: '登录过期，请重新登录！',
                    type: 'error'
                })

                storage.clear();

                setTimeout(() => { location.href = '/login' }, 1000);
                break;
            default:
                // ElMessage({
                //     message: response.data.msg,
                //     type: 'error'
                // })
                return Promise.reject(response.data)
        }
    }  
}, error => {
    if (error && error.response) {
        loadingInstance.close();
        const status = error.response.status;
        switch (status) {
            case 404:
                ElMessage({
                    message: '请求的资源不存在',
                    type: 'error'
                })
                break;
            case 500:
                ElMessage({
                    message: '服务器请求出错！',
                    type: 'error'
                })
                break;
            default:
                ElMessage({
                    message: '其他错误',
                    type: 'error'
                })
        }
    } else {
        loadingInstance.close();
        ElMessage({
            message: '请求失败',
            type: 'error'
        })
    }
})

export const get = (url, params) => {
    return request({
        url,
        method: 'get',
        params
    })
}

export const post = (url, params) => {
    return request({
        url,
        method: 'post',
        data: params
    })
}
