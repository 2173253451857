export default {
  "邮箱注册": 'Email registration',
  "姓名": 'Full Name',
  "英文": 'English',
  '姓': 'Last Name',
  '名': 'First Name',
  "国家或地区": "Country or Region",
  "邮政编码": "Postal Code",
  "省(英文)": "Province (English)",
  "市(英文)": "City (English)",
  "收货地址第1行(英文)": "Delivery Address Line 1 (English)",
  "收货地址第2行(英文)": "Delivery Address Line 2 (English)",
  "手机号": "Mobile Number",
  "性别": "Gender",
  "生日年月": "Date of Birth",
  "请输入姓": "Please Enter Last Name",
  "请输入名": "Please Enter First Name",
  "选择国家或地区": "Select Country or Region",
  "请输入邮政编码": "Please Enter Postal Code",
  "请输入省": "Please Enter Province",
  "请输入市": "Please Enter City",
  "请输入收货地址": "Please Enter Delivery Address",
  "请输入手机号": "Please Enter Mobile Number",
  "重要提示": "Important Notice",
  "在此处添加或修改收货地址只会反映到修改后所下的订单不会对过往的订单产生影响": 
    "Adding or modifying the delivery address here will only affect orders placed after the modification and will not impact past orders.",
  "男性": "Male",
  "女性": "Female",
  "保密": "Confidential",
  "生日年月": "Date of Birth",
  "提交注册": "Submit Registration",
  "注册成功": "Registration Successful",
  "请输入密码(6-12位字母+数字)": "Please Enter Password (6-12 letters + numbers)",
  "确认密码(6-12位字母+数字)": "Confirm Password (6-12 letters + numbers)",
  "密码": "Password",
  "再次输入密码": "Re-enter Password",
  "请选择出生年月日": "Please Select Date of Birth",
  "号为必填": "Number is Required",
  "验证码": "Verification Code",
  "新密码": "New Password",
  "提交": "Submit",
  "两次密码输入不一致": "Passwords Do Not Match",
  "请选择国家": "Please Select Country",
  "请输入地址": "Please Enter Address",
  "请输入电话": "Please Enter Phone Number",
  "请输入新密码": "Please Enter New Password",
  "首页": "Home",
  "活动": "Events",
  "资讯": "News & Information",
  "会员单位": "Member Organizations",
  "邮箱：": "Email：",
  "网址：": "Website：",
  "公众号二维码": "QR Code",
  "选择语言": "Select Language",
  "地址：": "Address：",
  "更多": 'More',
  "查看详情": 'View details',
  '可前往下载中心获取相关材料': 'You can go to the download center and download the complete brochure in PDF format',
  '登录后才能查看会员专区信息哦~': 'You can only view member zone information after logging in~',
  立即登录: 'Login Now',
  '中国（德国）研发创新联盟': 'Chinesische F&E Innovationsunion in Deutschland e. V.',
  登录: 'Login',
  '登录成功': 'Login Successful',
  地址: 'Address',
  邮箱: 'Email',
  工作时间: 'Working hours',
  资料下载: 'Data Download',
  暂无数据: 'No data available',
  点击下载: 'Click to download',
  '发布时间：': 'Release time：',
  姓名: 'Full name',
  会员单位: 'Member units',
  联盟职务: 'Alliance position',
  请输入密码: 'Please input a password',
  请输入账号: 'Please enter your account',
  '暂无数据哦~': 'No data available ~',
  
  "有效期：": "Validity Period：",
  "负责人：": "Person in Charge：",
  "联络人：": "Contact Person：",
  "联系邮箱：": "Contact Email：",
  "地址信息：": "Address Information：",
  "消息通知": "Message Notification",
  "意见反馈": "Feedback",
  "会员单位名称": "Member Organization Name",
  "请输入会员单位名称": "Please Enter the Member Organization Name",
  "联系人": "Contact Person",
  "请输入联系人": "Please Enter the Contact Person",
  "联系人职务": "Position of Contact Person",
  "请输入邮箱": "Please Enter Email",
  "微信": "WeChat",
  "请输入微信": "Please Enter WeChat ID",
  "内容详述": "Detailed Description",
  "请输入内容详述": "Please Enter Detailed Description",
  "提交": "Submit",
  '请输入联系人职务': "Please enter the contact person's job title",
  '预览': 'Preview',
  '请先登录': 'Please log in first',
  '提示': 'Prompt',
  '确定': 'Confirm',
  '取消': 'Cancel',
  '登录下载': 'Login to Download',
  '请求接口失败:': 'Request interface failed:',
  "资讯": "News",
  "活动": "Events",
  "会员中心": "Member Center",
  "关于联盟": "About the Alliance",
  "专委会": "Special Committees",
  "联系我们": "Contact Us",
  "下载中心": "Download Center",
  "单位名称": "Unit Name",
  "会员类型": "Member Type",
  "会员专区仅对联盟会员开放，请登录。": "The member area is only open to CFEID members, please log in.",
  "非会员可访问页面“会员名录”和“会员动态”。": 'Non-members can access the pages "Member Directory" and "Member News".',
  "登录遇到任何问题请联系联盟秘书处": "If you encounter any problems when logging in, please contact the secretariat",
  "重要资讯": "Important information"
}
