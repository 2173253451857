<template>
  <header>
    <div class="container w-[1200px] md:w-[1400px] flex flex-col mx-auto py-2">
      <div class="flex items-center justify-between">
        <div class="cursor-pointer" @click="router.push({ name: 'home' })">
          <img class="md:h-[70px]" src="@/assets/logo.png" alt="">
        </div>
        <div class="flex items-center justify-end">
          <ul class="flex items-center">
            <li class="cursor-pointer w-nav w-[100px]" v-for="(item, index) in navList" :key="index"
              :class="curIndex == index ? 'active' : ''">
              <span class="w-navver" @click="navigateNavTo(item, index)">{{ item.name }}</span>
              <ul v-if="item.child" class="w-child absolute bg-[#fff] w-[160px] top-[76px] right-[-35px] z-10">
                <li class="w-subnav h-[40px]]" v-for="(child, i) in item.child" :key="i"
                  :class="subNavId == child.aid ? 'active' : ''" @click="navigateTo(item, index, i, child)">{{
                    child.name
                  }}</li>
              </ul>
            </li>
          </ul>
          <div class="ml-4" @click="onLanguage">
            <img class="h-[25px] w-[25px] cursor-pointer" src="@/assets/images/language.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
const emits = defineEmits(['on-language'])
import { h, ref, reactive, computed, shallowRef, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const router = useRouter()
const route = useRoute();
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import { useStore } from 'vuex'
const store = useStore();
//取到state面的local
const local = computed(() => store.state.local);

const curIndex = computed({
  get: () => store.state.curIndex,
  set: (value) => store.commit('setCurIndex', value)
});

const props = defineProps({
  navList: {
    type: Array,
    default() {
      return []
    }
  }
});

const onLanguage = (item) => {
  emits('on-language');
}

const subNavId = computed({
  get: () => store.state.childId,
  set: (value) => {
    store.commit('setSubNavId', value)
  }
})

//导航
const navigateNavTo = (item, index) => {
  curIndex.value = index;
  store.commit('setCurIndex', index);
  switch (index) {
    case 3:
      item.childId = 32
      break;
    case 4:
      item.childId = 45
      break;
    case 5:
      item.childId = 50
      break;
    case 6:
      item.childId = 54
      break;
  }
  store.commit('setSubNavId', item.childId);
  router.push({
    name: item.path,
    params: {
      pid: item.aid,
      name: item.name,
      aid: item.childId ? item.childId : item.aid
    }
  })
}

// 子导航
const navigateTo = (item, index, i, child) => {
  // console.log(item)
  // console.log(child)
  curIndex.value = index;
  store.commit('setCurIndex', index);
  subNavId.value = child.aid
  store.commit('setSubNavId', child.aid);
  router.push({
    name: child.path,
    params: {
      pid: item.aid,
      name: item.name,
      aid: child.aid
    } 
  })
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
}

.w-navver {
  display: inline-block;
  font-family: 'PingFang SC-Regular', Avenir, Helvetica, Arial, sans-serif;
  line-height: 20px !important;
}

.w-subnav.active {
  color: #fff;
  background-color: #DF0913;
  font-weight: 600;
}

.w-child {
  white-space: nowrap;
  /* 防止文字换行 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 超出部分显示为省略号 */
  max-height: 0;
  transition: max-height 0.5s ease-out;

  &>li {
    height: 45px;
    line-height: 45px;
    width: 100%;
    color: #333;
    -webkit-line-clamp: 1;
    /* 显示的行数 */
    -webkit-box-orient: vertical;
    white-space: nowrap;
    /* 防止文字换行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
    /* 超出部分显示为省略号 */
    @apply px-4 hover:bg-[#DF0913] hover:text-white
  }
}

.w-nav {
  height: 60px;
  line-height: 60px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  /* 创建底部横线 */
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    height: 4px;
    border-radius: 10px;
    background-color: #DF0913;
    transition: width 0.3s ease, left 0.3s ease;
  }

  &:hover::after {
    width: 30px;
    left: 35%;
    border-radius: 10px;
  }

  &:hover {
    .w-child {
      max-height: 500px;
      /* 足够高的值以容纳所有列表项 */
      transition: max-height 0.5s ease-in;
    }
  }

  &.active {
    color: #DF0913;
    font-weight: 600;
  }
  .w-subnav{
    font-weight: normal;
  }
}
</style>
