<template>
  <el-config-provider :locale="locale">
    <router-view/>
  </el-config-provider>
</template>

<script setup>
import { computed, ref } from 'vue'

// locale  持久化配置
const language = ref('')
const locale = computed(() => {
  // console.log(language.value)
  language.value === 'zhCN' ? 'en' : 'zhCN'
})

const toggle = () => {
  language.value = language.value === 'zhCN' ? 'en' : 'zhCN'
}
</script>

<style lang="scss">
#app {
  font-family: 'PingFang SC-Regular', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.bg-red{
  background-color: #DF0913;
}
</style>
