import storage from '@/utils/storage'
import { createStore } from 'vuex'

export default createStore({
  state: {
    curIndex: storage.get('curIndex') ? storage.get('curIndex') : 0,
    childId: '',
    token: storage.get('token') || '',
    userinfo: storage.get('userinfo') || {},
    local: storage.get('Language') || 'zh-Hans'
  },
  getters: {
  },
  mutations: {
    setLocal(state, data) {
      state.local = data
      storage.set('Language', data)
    },

    setToken(state, data) {
      state.token = data
      storage.set('token', data)
    },

    setUserInfo(state, data) {
      state.userinfo = data
      storage.set('userinfo', data)
    },

    //当前高亮的菜单索引
    setCurIndex(state, index) {
      state.curIndex = index
      storage.set('curIndex', index)
    },

    setSubNavId (state, data) {
      state.childId = data
      storage.set('childId', data)
    }
  },
  actions: {
  },
  modules: {
  }
})
