import { get, post } from './request';


//查询联盟理事会
export const post675baa2f216d4 = (params)=> post('/api/m8135/675baa2f216d4', params)

//意见反馈提交接口
export const post671320b68519f = (params)=> post('/api/m8135/671320b68519f', params)

//会员专区资料下载
export const post675bf42a27656 = (params)=> post('/api/m8135/675bf42a27656', params)
//获取个人资料
export const post5c78c4772da97 = (params)=> post('/api/m8135/5c78c4772da97', params)
//系统文章-获取平台公告列表
export const post6415752f56d0f = (params)=> post('/api/m8135/6415752f56d0f', params)

//登录
export const post5c78dbfd977cf = (params)=> post('/api/m8135/5c78dbfd977cf', params)

//系统消息-获取指定类型的消息列表  会员中心消息通知
export const post6424280846288 = (params)=> post('/api/m8135/6424280846288', params)


//文章资讯列表, 所有下载
export const post64219a95b6200 = (params)=> post('/api/m8135/64219a95b6200', params)
//查询子菜单
export const post67385dfd43347 = (params)=> post('/api/m8135/67385dfd43347', params)


//文章列表-获取文章详情
export const post64219c5dc8d0d = (params)=> post('/api/m8135/64219c5dc8d0d', params)
//文章列表-获取文章列表
export const post6421a00e4fa4e  = (params)=> post('/api/m8135/6421a00e4fa4e', params)
//获取 协会简介 资讯 文章 单页
export const post641568f1b6f87 = (params)=> post('/api/m8135/641568f1b6f87', params)
//首页查询专委会、资讯、活动及会员单位接口
export const get67136999c8473= (params)=> post(`/api/m8135/67136999c8473`, params)
//会员中心 会员提交反馈 新意见反馈
export const get671320b68519f= (params)=> post(`/api/m8135/671320b68519f`, params)
//系统消息-获取指定类型的消息列表  会员中心消息通知
export const get64219a95b6200 = (params)=> get('/api/m8135/64219a95b6200', params)


//顶部导航
export const get67136603afdea= (params)=> post(`/api/m8135/67136603afdea`, params)
//轮播图
export const get641e624160dd0= (params)=> post(`/api/m8135/641e624160dd0`, params)
// 网站底部栏配置项
export const get67137f2b11bdd = (params)=> get('/api/m8135/67137f2b11bdd', params)


