<template>
  <wHeader v-if="navList.length" :navList="navList" @on-language="onOnLanguage"></wHeader>

  <div class="h-[90px]"></div>


  <div>
    <router-view></router-view>
  </div>

  <wFooter :navList="navList" :footInfo="footInfo"></wFooter>

  <el-dialog v-model="outerVisible" :title="$t('选择语言')" width="600">
    <div class="flex flex-col items-center justify-center mt-5">
      <div class="h-[40px] w-[200px] text-[16px] cursor-pointer hover:text-[#DF0913] flex items-center"
        :class="curIndex == index ? 'text-[#DF0913]' : ''" v-for="(item, index) in LANGUAGES" :key="index"
        @click="changeLanguage(item, index)">
        <img class="w-[20px] h-[20px] mr-10" :src="curIndex == index ? checked : check" alt="" />
        {{ item.name }}
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="outerVisible = false">{{ $t('取消') }}</el-button>
        <el-button type="danger" @click="handleLanglage">{{ $t('确定') }}</el-button>
      </div>
    </template>
  </el-dialog>

</template>

<script setup>
import check from '@/assets/images/check.png'
import checked from '@/assets/images/checked.png' // 需要添加hover状态的图片
import { validate } from '@/utils/formValidate'
import { h, ref, reactive, computed, shallowRef, onMounted } from 'vue'
import wHeader from "@/components/w-Header";
import wFooter from "@/components/w-Footer";
import { useRoute, useRouter } from 'vue-router'
const route = useRoute();
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import { get67136603afdea, get67137f2b11bdd } from '@/apis/api'
import { LANGUAGES, storage } from '@/apis/config'
import { useStore } from 'vuex'
const store = useStore();

//翻译弹窗
const outerVisible = ref(false)
const curIndex = ref(null)
const language = ref(null)
const local = computed(() => store.state.local);
const changeLanguage = (item, index) => {
  language.value = item.value
  curIndex.value = index
}

//确定语言
const handleLanglage = () => {
  outerVisible.value = false
  store.commit('setLocal', language.value);
  window.location.reload()
}

//回显语言
const onOnLanguage = () => {
  outerVisible.value = true
  if (storage.get('Language')) {
    let local = LANGUAGES.find(item => item.value == storage.get('Language'))
    curIndex.value = LANGUAGES.findIndex(item => item.value == storage.get('Language'))
  } else {
    let local = LANGUAGES[0]
    curIndex.value = 0
  }
}


//头部导航
const navList = ref([])
const getNavFuns = async () => {
  const { code, data } = await get67136603afdea()
  if (code == 1) {
    data.unshift({
      aid: 10,
      name: t('首页'),
      path: 'home'
    })
    // console.log(data)
    navList.value = data
  }
}

const footInfo = ref({})
const getFootInfoFuns = async () => {
  try {
    const { code, data } = await get67137f2b11bdd()
    if (code == 1) {
      footInfo.value = data
    }
  } catch (error) {
    console.error(t('请求接口失败:'), error)
  }
}

onMounted(() => {
  getNavFuns()
  getFootInfoFuns()
})

</script>

<style lang="scss" scoped>
:deep(.t-none-icon.is-required:not(.is-no-asterisk).asterisk-left>.el-form-item__label:before) {
  color: #fff;
}

.w-btn {
  margin-top: 40px;
  margin-bottom: 30px;
  @apply w-full md:w-[300px] md:mx-auto h-[46px] bg-[#E44040] text-[16px] text-[#fff] flex items-center justify-center hover:opacity-90 border cursor-pointer
}

:deep(.el-icon.el-input__icon p) {
  color: #333;
  font-style: normal;
}

:deep(.el-radio__input.is-checked .el-radio__inner) {
  border-color: #E44040;
  background: #E44040;
}

:deep(.el-radio__input.is-checked+.el-radio__label) {
  color: #E44040;
}

.my-textarea :deep(.el-textarea__inner) {
  height: 90px;
  background-color: #f8f8f8;
}

.my-form-item :deep(.el-form-item__content) {
  display: flex;
  justify-content: space-between;
}
</style>
