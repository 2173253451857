
import { get, post } from './request';
import storage from "../utils/storage"
const BASE_URL = process.env.VUE_APP_BASE_URI;

const LANGUAGES = [
    {
        name: '中文 (简体)',
        value: 'zh-Hans'
    }, 
    {
        name: 'English',
        value: 'en'
    }
]; //语言配置

export {
    BASE_URL, get, post,
    LANGUAGES,
    storage
}