export default {
  邮箱注册: '邮箱注册',
  姓名: '姓名',
  英文: '英文',
  '姓': '姓',
  '名': '名',
  国家或地区: '国家或地区',
  邮政编码: '邮政编码',
  '省(英文)': '省(英文)',
  '市(英文)': '市(英文)',
  '收货地址第1行(英文)': '收货地址第1行(英文)',
  '收货地址第2行(英文)': '收货地址第2行(英文)',
  手机号: '手机号',
  性别: '性别',
  生日年月: '生日年月',
  请输入姓: '请输入姓',
  请输入名: '请输入名',
  选择国家或地区: '选择国家或地区',
  请输入邮政编码: '请输入邮政编码',
  请输入省: '请输入省',
  请输入市: '请输入市',
  请输入收货地址: '请输入收货地址',
  请输入手机号: '请输入手机号',
  重要提示: '重要提示',
  在此处添加或修改收货地址只会反映到修改后所下的订单不会对过往的订单产生影响: '在此处添加或修改收货地址只会反映到修改后所下的订单不会对过往的订单产生影响',
  男性: '男性',
  女性: '女性',
  保密: '保密',
  生日年月: '生日年月',
  提交注册: '提交注册',
  注册成功: '注册成功',
  '请输入密码(6-12位字母+数字)': '请输入密码(6-12位字母+数字)',
  '确认密码(6-12位字母+数字)': '确认密码(6-12位字母+数字)',
  密码: '密码',
  再次输入密码: '再次输入密码',
  请选择出生年月日: '请选择出生年月日',
  号为必填: '号为必填',
  验证码: '验证码',
  新密码: '新密码',
  提交: '提交',
  两次密码输入不一致: '两次密码输入不一致',
  请选择国家: '请选择国家',
  请输入地址: '请输入地址',
  请输入电话: '请输入电话',
  请输入新密码: '请输入新密码',
  "首页": "首页",
  '活动': '活动',
  "资讯": "资讯",
  '会员单位': '会员单位',
  "邮箱：": "邮箱：",
  '网址：': '网址：',
  '公众号二维码': '公众号二维码',
  '选择语言': '选择语言',
  '地址：': '地址：',
  '更多': '更多',
  '查看详情': '查看详情',
  '可前往下载中心获取相关材料': '可前往下载中心获取相关材料',
  '登录后才能查看会员专区信息哦~': '登录后才能查看会员专区信息哦~',
  '立即登录': '立即登录',
  '中国（德国）研发创新联盟': '中国（德国）研发创新联盟',
  '登录': '登录',
  '登录成功': '登录成功',
  '地址': '地址',
  '邮箱': '邮箱',
  '工作时间': '工作时间',
  '资料下载': '资料下载',
  '暂无数据': '暂无数据',
  '点击下载': '点击下载',
  '发布时间：': '发布时间：',
  '姓名': '姓名',
  '会员单位': '会员单位',
  '联盟职务': '联盟职务',
  '请输入密码': '请输入密码',
  '请输入账号': '请输入账号',
  '暂无数据哦~': '暂无数据哦~',
  '有效期：': '有效期：',
  '负责人：': '负责人：',
  '联络人：': '联络人：',
  '联系邮箱：': '联系邮箱：',
  '地址信息：': '地址信息：',
  '消息通知': '消息通知',
  '意见反馈': '意见反馈',
  '会员单位名称': '会员单位名称',
  '请输入会员单位名称': '请输入会员单位名称',
  '联系人': '联系人',
  '请输入联系人': '请输入联系人',
  '联系人职务': '联系人职务',
  '请输入邮箱': '请输入邮箱',
  '微信': '微信',
  '请输入微信': '请输入微信',
  '内容详述': '内容详述',
  '请输入内容详述': '请输入内容详述',
  '提交': '提交',
  '请输入联系人职务': '请输入联系人职务',
  '预览': '预览',
  '请先登录': '请先登录',
  '提示': '提示',
  '确定': '确定',
  '取消': '取消',
  '登录下载': '登录下载',
  '请求接口失败:': '请求接口失败:',
  '资讯': '资讯',
  '活动': '活动',
  '会员中心': '会员中心',
  '关于联盟': '关于联盟',
  '专委会': '专委会',
  '联系我们': '联系我们',
  '下载中心': '下载中心',
  "单位名称": "单位名称",
  "会员类型": "会员类型",
  "会员专区仅对联盟会员开放，请登录。": "会员专区仅对联盟会员开放，请登录。",
  "非会员可访问页面“会员名录”和“会员动态”。": "非会员可访问页面“会员名录”和“会员动态”。",
  "登录遇到任何问题请联系联盟秘书处": "登录遇到任何问题请联系联盟秘书处",
  "重要资讯": "重要资讯"
}
