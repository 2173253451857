import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { useStore } from 'vuex'
import storage from '@/utils/storage'
import Layout from '@/views/Layout.vue'
import ForgetPassword from '../views/ForgetPassword.vue'


const routes = [
  {
    path: "/login",
    name: "login",
    meta: { title: '登录' },
    component: () =>import("@/views/Login.vue")
  },
  {
    path: '/',
    component: Layout,
    children:[
      {
        path: "",
        name: "home",
        meta: { title: '首页' },
        component: () =>import("@/views/Home.vue")
      },
      {
        path: "/information",
        name: "information",
        meta: { title: '资讯' },
        children:[
          {
            path: "infoList/:pid/:name/:aid",
            name: "infoList",
            meta: { title: '资讯列表' },
            component: () => import("@/views/Information/List.vue")
          },
          {
            path: "detail/:aid",
            name: "infoDetail",
            meta: { title: '资讯详情' },
            component: () => import("@/views/Information/Detail.vue")
          }
        ]
      },
      {
        path: "/activity",
        name: "activity",
        meta: { title: '活动' },
        children:[
          {
            path: "actList/:pid/:name/:aid",
            name: "actList",
            meta: { title: '活动列表' },
            component: () => import("@/views/Activity/List.vue")
          },
          {
            path: "detail/:aid",
            name: "actDetail",
            meta: { title: '活动详情' },
            component: () => import("@/views/Activity/Detail.vue")
          }
        ]
      },
      {
        path: "/committee",
        name: "committee",
        meta: { title: '专委会' },
        children:[
          {
            path: "commitIndex/:pid/:name/:aid",
            name: "commitIndex",
            meta: { title: '专委会' },
            component: () => import("@/views/Committee/commitIndex.vue")
          }
        ]
      },
      {
        path: "/alliance",
        name: "alliance",
        meta: { title: '关于联盟' },
        children:[
          {
            path: "allianceIndex/:pid/:name/:aid",
            name: "allianceIndex",
            meta: { title: '联盟简介' },
            component: () => import("@/views/Alliance/allianceIndex.vue")
          },
          {
            path: "allOrganization/:pid/:name/:aid",
            name: "allOrganization",
            meta: { title: '组织架构' },
            component: () => import("@/views/Alliance/allOrganization.vue")
          },
          {
            path: "allCouncil/:pid/:name/:aid",
            name: "allCouncil",
            meta: { title: '理事会' },
            component: () => import("@/views/Alliance/allCouncil.vue")
          },
          {
            path: "allianceRules/:pid/:name/:aid",
            name: "allianceRules",
            meta: { title: '联盟章程' },
            component: () => import("@/views/Alliance/allianceRules.vue")
          }
        ]
      },
      {
        path: "/member",
        name: "member",
        meta: { title: '会员中心' },
        children:[
          {
            path: "memberIndex/:pid/:name/:aid",
            name: "memberIndex",
            meta: { title: '会员专区' },
            component: () => import("@/views/Member/MemberIndex.vue")
          },
          {
            path: "memberDirectory/:pid/:name/:aid",
            name: "memberDirectory",
            meta: { title: '会员名录' },
            component: () => import("@/views/Member/MemberDirectory.vue")
          },
          {
            path: "memberDynamics/:pid/:name/:aid",
            name: "memberDynamics",
            meta: { title: '会员动态' },
            component: () => import("@/views/Member/MemberDynamics.vue")
          }
        ]
      },
      {
        path: "/contactus",
        name: "contactus",
        meta: { title: '联系我们' },
        children:[
          {
            path: "joinSpecial/:pid/:name/:aid",
            name: "joinSpecial",
            meta: { title: '加入专委会' },
            component: () => import("@/views/ContactUs/JoinSpecial.vue")
          },
          {
            path: "joinAlliance/:pid/:name/:aid",
            name: "joinAlliance",
            meta: { title: '加入联盟' },
            component: () => import("@/views/ContactUs/JoinAlliance.vue")
          },
          {
            path: "contactType/:pid/:name/:aid",
            name: "contactType",
            meta: { title: '联系方式' },
            component: () => import("@/views/ContactUs/ContactType.vue")
          }
        ]
      },
      {
        path: "/download/:aid/:name",
        name: "download",
        meta: { title: '下载中心' },
        component: () =>import("@/views/Download.vue")
      },
      {
        path: "/theme",
        name: "theme",
        meta: { title: '主题设置' },
        component: () =>import("@/views/Theme.vue")
      }
    ]
  },
  {
    path: '/forget',
    name: 'forget',
    component: ForgetPassword
  }
]

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  const store = useStore();
  // Update condition to check for root path only
  if (to.path === '/') {
    storage.remove('curIndex');
    store.commit('setCurIndex', 0);
  }

  next();
});

export default router
