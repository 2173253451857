<template>
  <div class="w-full mx-auto md:w-[600px]">
    <div class="flex bg-white px-3 md:px-10">
      <el-form ref="ruleFormRef" :model="ruleForm" status-icon :rules="rules" label-position="top">
        <div class="section mb-5">
          <div class="py-3 flex items-center text-bold text-xl border-b mt-5">
            <div class=" text-[#606266] text-[16px]">{{ $t('邮箱注册') }}</div>
            <div class="text-red-600 text-[14px] ml-3">*{{ $t('号为必填') }}</div>
          </div>
          <div class="mt-4 my-form-item">
            <el-form-item :label="$t('验证码')" prop="activation_key">
              <el-input v-model="ruleForm.activation_key" type="string" :disabled="true" autocomplete="off"></el-input>
            </el-form-item>

            <div class="py-3 text-bold text-xl border-b mb-4 md:px-8"> <span class="text-red-600">*</span>{{ $t('密码') }}</div>
            <el-form-item :label="$t('新密码')" prop="password">
              <el-input v-model="ruleForm.password" type="password" 
                min="6"
                max="12"
                :placeholder="$t('请输入密码(6-12位字母+数字)') "
                show-password/>
            </el-form-item>
            <el-form-item :label="$t('再次输入密码')" prop="affirmPassword">
              <el-input v-model="ruleForm.affirmPassword" type="password" 
                min="6"
                max="12"
                :placeholder="$t('确认密码(6-12位字母+数字)') "
                show-password/>
            </el-form-item>

            <div class="w-btn" @click="submit"> {{ $t('提交') }} </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { h, ref, reactive, computed, shallowRef, onMounted } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
const router = useRouter()
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const BASE_URL = process.env.VUE_APP_BASE_URI;

const ruleFormRef = ref() // 表单数据
const ruleForm = reactive({
  sex: '1',
})

/**
 * @description 确认密码的校验
 */
 const validateAffirmPassword = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(t('请再次输入密码')))
  } else if (value !== ruleForm.password) {
    callback(new Error(t('两次密码输入不一致')))
  } else {
    callback()
  }
}
// 规则校验
const rules = reactive({
  password: [{ required: true, message: t('请输入新密码'), trigger: 'blur' }],
  affirmPassword: [{ validator: validateAffirmPassword, trigger: 'blur' }]
})

const submit = async () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        const { data } = await axios.post(`${BASE_URL}/v1/66c0248d1e874`, { ...ruleForm})
        if (data.code == 1) {
          ElMessage.success(t('提交成功')) 
          setTimeout(() => {
            window.location = 'https://wawahyhwpc.hntvhbygh.com/login/login'
          }, 1000)
        } else{
          ElMessage.error(data.msg)
        }
      } catch (error) {
        ElMessage.error(error) 
      }
    }
  })
}

//方法:用来提取code
const getUrlCode = (name) => {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, '' ])[1].replace(/\+/g, '%20')) || null
}



const urlKey = ref('')
onMounted(() => {
  urlKey.value = getUrlCode('key')
  ruleForm['activation_key'] = urlKey.value
})

</script>

<style lang="scss" scoped>

:deep(.t-none-icon.is-required:not(.is-no-asterisk).asterisk-left>.el-form-item__label:before){
  color: #fff;
}

.w-btn{
  margin-top: 40px;
  margin-bottom: 30px;
  @apply w-full md:w-[300px] md:mx-auto h-[46px] bg-[#E44040] text-[16px] text-[#fff] flex items-center justify-center hover:opacity-90 border cursor-pointer
}
:deep(.el-icon.el-input__icon p) {
  color: #333;
  font-style: normal;
}

:deep(.el-radio__input.is-checked .el-radio__inner) {
    border-color: #E44040;
    background: #E44040;
}

:deep(.el-radio__input.is-checked+.el-radio__label){
  color: #E44040;
}

.my-textarea :deep(.el-textarea__inner) {
  height: 90px;
  background-color: #f8f8f8;
}

.my-form-item :deep(.el-form-item__content) {
  display: flex;
  justify-content: space-between;
}
</style>
